<template>
<div>
	<div class="main-container">

		<div style="text-align: right; margin-top: 10px;">
			<div @click="language = 'nb_NO'" class="flag" style="background-position: 0 -48px;"></div>
			<div @click="language = 'sv_SE'" class="flag" style="background-position: 0 -32px;"></div>
			<div @click="language = 'dk_DK'" class="flag" style="background-position: 0 -16px;"></div>
			<div @click="language = 'en_EN'" class="flag" style=""></div>
		</div>

		<div class="main-logo">
			<img src="https://www.raeder.no/globalassets/logo.svg" />
		</div>

		<div v-if="loading" class="loader smooth">
			<img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif"/>
			<p v-if="loading">{{ loadText }}</p>
		</div>

		<div v-if="!loading" class="form-container">
			<div class="form-header">
			</div>
			<div class="form-content">

				<ve-form v-show="step == 1 && !completed" ref="form1">
					<div style="padding: 25px 25px 5px 25px; text-align: center">
						<h1>{{ locale('landing_page_headline') }}</h1>
						<p v-html="locale('landing_page_text')"></p>
					</div>
				</ve-form>

				<ve-form v-show="step == 2 && !completed" ref="form2">
					<div style="padding: 20px 25px 0px 25px; text-align: center">
						<h2>{{ locale('connection') }}</h2>
					</div>
					<div class="row form-padding">

						<ve-col xs12 md12><ve-form-group>
							<ve-label :label="locale('connection_question')" required></ve-label>
							<ve-select
								v-model="contactKey"
								:searchable="false"
								:options="contactList"
								:rules="'required'"
							></ve-select>
						</ve-form-group></ve-col>

					</div>
				</ve-form>

				<ve-form v-show="step == 3 && !completed" ref="form3">
					<div style="padding: 20px 25px 0px 25px; text-align: center">
						<h2>{{ locale('contact_details') }}</h2>
					</div>
					<div class="row form-padding">

						<ve-col xs12 md12><ve-form-group>
							<ve-label :label="locale('address')" required></ve-label>
							<ve-input v-model="ud.person.address" :rules="'required'"></ve-input>
						</ve-form-group></ve-col>

						<ve-col xs12 md4><ve-form-group>
							<ve-label :label="locale('zip')" required></ve-label>
							<ve-input v-model="ud.person.zip" 
								:dictionary="zipJson" 
								mask="####"
								@on-dictionary-match="ud.person.city = $event"
								rules="required|min:4|max:4"
							></ve-input>
						</ve-form-group></ve-col>

						<ve-col xs12 md8><ve-form-group>
							<ve-label :label="locale('city')" required></ve-label>
							<ve-input v-model="ud.person.city" :rules="'required'"></ve-input>
						</ve-form-group></ve-col>

						<ve-col xs12 md6><ve-form-group>
							<ve-label :label="locale('email')" required></ve-label>
							<ve-input v-model="ud.person.email" :rules="'required|email'"></ve-input>
						</ve-form-group></ve-col>

						<ve-col xs12 md6><ve-form-group>
							<ve-label :label="locale('phone')" required></ve-label>
							<ve-input v-model="ud.person.phone" :rules="'required'"></ve-input>
						</ve-form-group></ve-col>

					</div>
				</ve-form>

				<ve-form v-show="completed" ref="form4">
					<div v-if="envelopePublished" style="padding: 25px; text-align: center">
						<h1>{{ locale('thank_you_page_headline') }}</h1>
						<p>{{ locale('thank_you_page_text') }}</p>
						<p v-if="config('redirect_url')">{{ locale('redirect_text') }}</p>
					</div>
					<div v-if="!envelopePublished" style="padding: 25px; text-align: center">
						<h2>{{ locale('thank_you_page_finalizing_text') }}</h2>
					</div>
				</ve-form>

			</div>
			<div v-if="!completed" class="form-footer">
				<button type="button" @click="onButtonClick" class="btn btn-primary next-btn">
					<span>{{ buttonText }}</span>
				</button>
			</div>
		</div>

	</div>
</div>
</template>
<script>
import axios from 'axios';
import { auth, envelopes, oidc } from '@verified/libvf'
import zipNO from '../assets/postnummer_no.json'

export default {
	name: "app",
	data() {
		return {
			loading: true,
			loadText: 'Laster skjema...',
			completed: false,
			envelopePublished: false,
			secondStage: false,
			exceptions: [],
			sheetName: 'raeder-id-kontroll',
			language: 'nb_NO',
			translations: {},
			configuration: {},
			contacts: [],
			contactList: [],
			contactKey: '',
			zipJson: undefined,
			step: 1,
			buttonText: '',
			amlData: {},
			ud: {
				person: {
					fname: '',
					lname: '',
					dob: '',
					address: '',
					zip: '',
					city: '',
					email: '',
					phone: ''
				},
				contact: {
					fname: '',
					lname: '',
					email: ''
				}
			}
		}
	},
	methods: {
		config(key) {
			if(this.configuration[key])
				return this.configuration[key].value
			return null
		},
		locale(key) {
			if(this.translations[key])
				return this.translations[key][this.language]
			return '...'
		},
		validateForm(form) {
			return this.$refs[`form${form}`].validate();
		},
		onButtonClick() {
			if (!this.secondStage) {
				this.startBankID();
			}else {
				if (this.validateForm(this.step)) {
					if (this.step == 3) {
						this.submitForm();
					}else {
						this.step = this.step + 1;
					}
				}
				if (this.step == 3) this.buttonText = this.locale('send_in');
			}
		},
		async startBankID() {
			this.loading = true
			try {
				auth.authenticate({
					token: this.config('jwt'),
					namespace: this.config('namespace')
				})
				// Redirect to BankID
				let redirectUrl = await oidc.norway.getAuthUrl(`/lib/iframes/redirect/raeder-id-kontroll.html/?sheetName=${this.sheetName}`)
				window.location.href = redirectUrl
			}
			catch(err) {
				console.log(err)
				this.exceptions.push(new Error("Unable to start BankID authentication. Make sure your access token is valid and try again."))
			}
		},
		async submitForm() {
			this.loading = true;
			this.loadText = this.locale('submit_form');
			try {
				// Get ræder contact info
				for (let i in this.contacts) {
					if (this.contacts[i].key == this.contactKey) {
						this.ud.contact.fname = this.contacts[i].fname;
						this.ud.contact.lname = this.contacts[i].lname;
						this.ud.contact.email = this.contacts[i].email;
					}
				}

				// Add stuff to userdata
				this.ud.config = this.config
				this.ud.translations = this.translations
				this.ud.aml = this.amlData.aml
				this.ud.lookupDate = (new Date()).toLocaleString('nb-NO')
				this.amlCheckDisabled = false

				// Add silent parties
				let metadata = {
					silentParties: [
						{
							givenName: this.ud.person.fname,
							familyName: this.ud.person.lname,
							email: this.ud.person.email
						},
						{
							givenName: this.ud.contact.fname,
							familyName: this.ud.contact.lname,
							email: this.ud.contact.email
						}
					],
					language: this.language
				};

				// Do envelope stuff

				// Create envelope and set userdata
				let envelope = await envelopes.create('raeder-id-kontroll')

				// Get and set bearer token
				const descriptor = await envelopes.getDescriptor('raeder-id-kontroll')
				const bearerToken = await envelope.getBearerToken(descriptor.flow.id)
				auth.setToken(bearerToken)

				await envelope.reflect()

				await envelope.put({ metadata: metadata })
				await envelope.firstTemplate().setUserData(this.ud)

				let fileName = this.config('file_name').replace('{firstname}', this.ud.person.fname).replace('{lastname}', this.ud.person.lname)
				fileName = fileName.replace('{ssn}', this.amlData.aml.nnin)

				await envelope.firstDocument().put({
					name: fileName
				})

				await envelope.publish()
				this.envelopePublished = true
				this.completed = true


				console.log("redirect in " + this.config('redirect_after_seconds') + " sec")
				// If a redirect is specified in the config, forward the user to that url in X seconds
				if(this.config('redirect_url')) {
					setTimeout(() => {
						console.log("redirecting")
						window.location.href = this.config('redirect_url') + '?envelope_uid=' + envelope.data.uid
					}, parseInt(this.config('redirect_after_seconds')) * 1000);
				}
			}
			catch(err) {
				console.log(err)
				this.exceptions.push(err)
			}
			this.loading = false;
		}
	},
	async created() {
		this.setIso(this.language);

		// Get translations and config
		try {
			const translations = await axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/translations`);
			const configuration = await axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/config`);
			const contacts = await axios.get(`https://sheets.web-services.verified.eu/${this.sheetName}/contacts`);
			this.translations = translations.data;
			this.configuration = configuration.data;
			for (let i in contacts.data) {
				let key = contacts.data[i].fname + ' ' + contacts.data[i].lname;
				this.contacts.push({
					key: key,
					fname: contacts.data[i].fname,
					lname: contacts.data[i].lname,
					email: contacts.data[i].email
				});
				this.contactList.push(key);
			}
		}catch(err) {
			console.log(err);
			return this.exceptions.push(new Error("Unable to get sheet data. Please try again later."));
		}

		this.zipJson = zipNO;
		this.buttonText = this.locale('start_id');

		// Set appearance from config
		document.getElementById('favicon').href = this.config("favicon");
		document.title = this.config('title');
		document.documentElement.style.setProperty('--primary', this.config('primary_color'));

		try {
			// If url has the tokens, it means the user has been redirected to us from the bankid auth
			if (oidc.norway.urlHasNecessaryTokens()) {

				this.step = 2;
				this.secondStage = true;
				this.loadText = this.locale('aml_loading');
				this.buttonText = this.locale('next');

				auth.authenticate({
					token: this.config('jwt'),
					namespace: this.config('namespace')
				})

				// Get firstname and lastname from the bankid tokens
				try {
					let tokenData = oidc.norway.decodeUrlTokens()
					this.ud.person.fname = tokenData.id_token.given_name;
					this.ud.person.lname = tokenData.id_token.family_name;
					this.ud.person.dob = tokenData.id_token.birthdate;
				}
				catch(err) {
					console.log(err)
					return this.exceptions.push(new Error("Failed to decode id_token and access_token from url"))
				}

				const amlCheckDisabled = this.config("aml_check_disabled") === "TRUE"
				
				if(!amlCheckDisabled) {
					// Get the AML data
					try {
						this.amlData = await oidc.norway.getAmlData()
					}
					catch(err) {
						console.log(err)
						return this.exceptions.push(new Error("Your BankID session has expired."))
					}
				}

			}
		}
		catch(err) {
			console.log(err)
			this.exceptions.push(err)
		}

		this.loading = false;
	}
}

</script>